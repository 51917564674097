import React from 'react'
import { graphql } from 'gatsby'

import { DefaultLayout } from '../layouts/defaultlayout'
import { ProjectList, Container } from '../components'

import { Navigation } from './_navigation'

export const query = graphql`
  query {
    projects: directus {
      ...ProjectsWithoutImagesFragment
    }
    logo: directus {
      ...LogoFragment
    }
  }
`

export default ({ data }: any) => {
  return (
    <DefaultLayout>
      <Navigation logo={data.logo.logo.logo.imageUrl} />
      <Container>
        <ProjectList projects={data.projects.projects} />
      </Container>
    </DefaultLayout>
  )
}
